import React from "react";
import LazyLoader from "@loadable/component";

import {
	URL_DASHBOARD,
	URL_USER,
	URL_PAGES,
	QUESTION_BANK,
	QUESTION_BANK_SCHOOLS,
	URL_LEGAL,
	URL_SETTINGS,
	URL_LEGISLATIVE_QUESTIONS,
	URL_LEGISLATIVE_BILL_BECOMES_LAW,
	URL_EXECUTIVE_ESCAPE_ROOM,
	URL_SCHOOL,
	URL_TEACHER,
	URL_SCHOOL_TEACHER,
	URL_SCHOOL_CLASS,
	URL_CLASS,
	URL_TEACHER_CLASSES,
	URL_SCHOOL_STUDENT,
	URL_SCHOOL_TEACHERS_STUDENT,
	URL_SCHOOL_ASSIGN_CLAAS_TO_STUDENT,
	URL_TEST_ASSIGN_TO_CLASS,
	URL_TEST_ASSIGN_TO_CLASS_ADMIN,
	URL_ALL_GAMES,
	URL_TEST_RESULT_STUDENTS,
	URL_TEST_RESULT_STUDENTS_ADMIN,
	URL_QUIZ_QUESTION,
	URL_FINAL_GAME_QUESTION,
	URL_SCHOOL_STUDENT_RESULT,
	URL_H_RAPID_FIRE_QUESTIONS,
	URL_HISTORY_BILL_NO_BILL,
	URL_F_RAPID_FIRE_QUESTIONS,
	URL_S_RAPID_FIRE_QUESTIONS,
	URL_SCHOOL_STUDENT_TEST_RESULT,
	URL_SCHOOL_STUDENT_GAME,
	QUESTION_BANK_MENU,
	URL_ENGLISH_CROSSWORD,
	URL_SCIENCE_PERIODIC_TABLE,
	URL_ENGLISH_CROSSWORD_DETAIL,
	URL_ECONOMICS,
	URL_SCHOOL_STUDENT_PROGRESS_REPORT,
	URL_MATHS,
	URL_SCIENCE_LOOSE_LINK,
	URL_SCIENCE_LOOSE_LINK_QUESTION,
	URL_SUBSCRIPTION,
	URL_UPGRADE_SUBSCRIPTION,
	URL_ENGLISH_QUESTIONS,
	URL_FOREIGN_LANGUAGE_QUIZ,
	URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY,
	URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY_QUESTION,
	URL_QUIZ_CATEGORY,
	URL_QUIZ_GAME,
} from "Helpers/Paths";

import {
	AppstoreOutlined,
	BarChartOutlined,
	CloudOutlined,
	HomeOutlined,
	KeyOutlined,
	QuestionCircleOutlined,
	UserOutlined,
	LockOutlined,
	FileProtectOutlined,
	DollarCircleOutlined,
	BookOutlined,
	TeamOutlined,
	BankOutlined,
	SnippetsOutlined,
	FlagOutlined,
	ContainerOutlined,
	ProjectOutlined,
	ClockCircleOutlined,
	FundOutlined,
	FileTextOutlined,
	// LineChartOutlined,
} from "@ant-design/icons";
import {
	ADMIN_ACCESS,
	ADMIN_SCHOOL_ACCESS,
	All_ACCESS,
	SCHOOL_ACCESS,
	SCHOOL_TEACHER_ACCESS,
	URL_CHANGE_PASSWORD,
	URL_CONSTITUTION_QUESTIONS,
	URL_EDIT_PROFILE,
	URL_JUDICIAL_SPIN_QUESTIONS,
	URL_JUDICIAL_SUPREME_COURT_QUESTIONS,
} from "../Helpers";

export default [
	//common routes
	{
		path: "/",
		exact: true,
		component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
		access: All_ACCESS,
	},
	{
		path: URL_DASHBOARD,
		exact: true,
		component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
		access: All_ACCESS,
		sidebar: {
			name: "Dashboard",
			icon: <BarChartOutlined />,
			access: All_ACCESS,
		},
	},
	{
		path: URL_USER,
		exact: true,
		component: LazyLoader(() => import("Components/Users/Users")),
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Users",
			icon: <TeamOutlined />,
			access: ADMIN_ACCESS,
		},
	},
	{
		path: URL_SCHOOL,
		exact: true,
		component: LazyLoader(() => import("Components/school/School")),
		access: ADMIN_ACCESS,
		sidebar: {
			name: "School",
			icon: <BookOutlined />,
			access: ADMIN_SCHOOL_ACCESS,
		},
	},
	{
		path: URL_SCHOOL_TEACHER + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/teacher/Teacher.js")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_TEACHER + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/teacher/Teacher.js")),
		access: SCHOOL_ACCESS,
		sidebar: {
			name: "Teacher",
			icon: <UserOutlined />,
		},
	},
	{
		path: URL_SCHOOL_STUDENT + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/students/Student")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_SCHOOL_STUDENT_RESULT + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/students/StudentResult")),
		access: All_ACCESS,
	},
	{
		path: URL_SCHOOL_STUDENT_PROGRESS_REPORT + ":studentId",
		exact: true,
		component: LazyLoader(() => import("Components/ProgressReport/ProgressReport")),
		access: All_ACCESS,
	},
	{
		path: URL_SCHOOL_STUDENT_TEST_RESULT,
		exact: true,
		component: LazyLoader(() => import("Components/school/students/StudentTests")),
		access: All_ACCESS,
	},
	{
		path: URL_SCHOOL_STUDENT_GAME,
		exact: true,
		component: LazyLoader(() => import("Components/school/students/StudentGamePage")),
		access: All_ACCESS,
	},
	{
		path: URL_SCHOOL_TEACHERS_STUDENT + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/students/Student")),
		access: SCHOOL_TEACHER_ACCESS,
		// access: SCHOOL_ACCESS,
		sidebar: {
			name: "Students",
			icon: <TeamOutlined />,
		},
	},
	{
		path: URL_SCHOOL_ASSIGN_CLAAS_TO_STUDENT + ":schoolId" + "/" + ":schoolClassId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/students/AssignClassToStudent")),
		access: All_ACCESS,
	},
	{
		path: URL_SCHOOL_CLASS + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/SchoolClass")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_CLASS + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/SchoolClass")),
		access: SCHOOL_TEACHER_ACCESS,
		sidebar: {
			name: "Class",
			icon: <BankOutlined />,
		},
	},
	{
		path: URL_TEACHER_CLASSES + ":schoolId" + "/" + ":teacherId",
		exact: true,
		component: LazyLoader(() => import("Components/school/AssignClassToTeacher/TeacherClasses")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_TEST_ASSIGN_TO_CLASS_ADMIN + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/test/Test")),
		access: ADMIN_ACCESS,
	},
	{
		path: URL_TEST_ASSIGN_TO_CLASS + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/test/Test")),
		access: SCHOOL_TEACHER_ACCESS,
		sidebar: {
			name: "Tests",
			icon: <SnippetsOutlined />,
		},
	},
	{
		path: URL_TEST_RESULT_STUDENTS + ":schoolClassTestId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/test/TestReults/TestResult")),
		access: All_ACCESS,
	},
	{
		path: URL_TEST_RESULT_STUDENTS_ADMIN + ":userId",
		exact: true,
		component: LazyLoader(() => import("Components/Users/UserResult/UserResult")),
		access: ADMIN_ACCESS,
	},
	// {
	//     path        :   URL_PAGES,
	//     exact       :   true,
	//     component   :   LazyLoader(() => import('Components/Dashboard/Dashboard')),
	//     sidebar     : {
	//         name    : "Pages",
	//         icon    :  <CloudOutlined />,
	//         showDivider : true
	//     }
	// },
	{
		path: URL_ALL_GAMES + ":schoolClassTestId",
		exact: true,
		component: LazyLoader(() => import("Components/school/SchoolClass/test/Games/Games")),
		access: All_ACCESS,
	},
	{
		path: URL_ENGLISH_CROSSWORD_DETAIL,
		exact: true,
		component: LazyLoader(() => import("Components/English/Crossword/CrossWord")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_MATHS.CONNECT4_QUESTION,
		exact: true,
		component: LazyLoader(() => import("Components/Maths/Connect4/Question")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_MATHS.MATHAHTZEE_QUESTION,
		exact: true,
		component: LazyLoader(() => import("Components/Maths/Mathahtzee/Question")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_SCIENCE_LOOSE_LINK_QUESTION,
		exact: true,
		component: LazyLoader(() => import("Components/Science/LooseLink/Question")),
		access: ADMIN_SCHOOL_ACCESS,
	},
	{
		path: URL_UPGRADE_SUBSCRIPTION + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/Subscription/UpgradeSubscription")),
		access: SCHOOL_ACCESS,
	},
	{
		path: URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY,
		exact: true,
		component: LazyLoader(() => import("Components/ForeignLanguage/Quiz/View/QuizCategory")),
		access: SCHOOL_ACCESS,
	},
	{
		path: URL_FOREIGN_LANGUAGE_QUIZ_CATEGORY_QUESTION,
		exact: true,
		component: LazyLoader(() => import("Components/ForeignLanguage/Quiz/View/CategoryQuestion")),
		access: SCHOOL_ACCESS,
	},
	{
		path: URL_QUIZ_CATEGORY,
		exact: true,
		component: LazyLoader(() => import("Components/Quiz/AddCategory")),
		access: SCHOOL_ACCESS,
	},
	{
		path: URL_QUIZ_QUESTION,
		exact: true,
		component: LazyLoader(() => import("Components/Quiz/Question")),
		access: SCHOOL_ACCESS,
	},
	/**
	 * CIVICS ROUTES
	 */
	{
		path: "/civics",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Civics",
			icon: <ProjectOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Constitution",
					icon: <CloudOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_CONSTITUTION_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Constitution/Question/Question")),
						},
					],
				},
				{
					name: "Judicial",
					icon: <HomeOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Spin Question",
							path: URL_JUDICIAL_SPIN_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/Judicial/SpinQuestion/Question")),
						},
						{
							name: "Supreme Court Question",
							path: URL_JUDICIAL_SUPREME_COURT_QUESTIONS,
							// visible : false,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Judicial/SupremeCourtQuestion/Question")),
						},
					],
				},
				{
					name: "Legislative",
					icon: <HomeOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Bill No Bill",
							path: URL_LEGISLATIVE_QUESTIONS,
							icon: <DollarCircleOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/Legislative/BillNoBill/Legislative.jsx")),
						},
						{
							name: "How Bill Becomes Law",
							path: URL_LEGISLATIVE_BILL_BECOMES_LAW,
							icon: <QuestionCircleOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/Legislative/BillBecomesLaw/BillBecomesLaw")),
						},
					],
				},
				{
					name: "Executive",
					icon: <LockOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Escape Room",
							path: URL_EXECUTIVE_ESCAPE_ROOM,
							icon: <KeyOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/Executive/EscapeRoom/Question")),
						},
					],
				},
				{
					name: "Quiz",
					icon: <QuestionCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Quiz",
							path: URL_QUIZ_GAME,
							icon: <QuestionCircleOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/Quiz/AddGameTitle")),
						},
					],
				},
				{
					name: "Final Game",
					icon: <FlagOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Final Game",
							path: URL_FINAL_GAME_QUESTION,
							icon: <FlagOutlined />,
							// visible : false,
							component: LazyLoader(() => import("Components/FinalGame/Question")),
						},
					],
				},
			],
		},
	},
	/**
	 * HISTORY ROUTES
	 */
	{
		path: "/history",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "History",
			icon: <FundOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Rapid Fire",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_H_RAPID_FIRE_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/History/RapidFire/Question")),
						},
					],
				},
				{
					name: "Present Or Past",
					icon: <DollarCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_HISTORY_BILL_NO_BILL,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/History/BillNoBill/Question")),
						},
					],
				},
			],
		},
	},
	/**
	 * FOREIGN_LANGUAGE ROUTES
	 */
	{
		path: "/foreign-language",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Foreign Language",
			icon: <FileTextOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Rapid Fire",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_F_RAPID_FIRE_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/ForeignLanguage/RapidFire/Question")),
						},
					],
				},
				{
					name: "Quiz",
					icon: <QuestionCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Question",
							path: URL_FOREIGN_LANGUAGE_QUIZ,
							exact: true,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/ForeignLanguage/Quiz/Question")),
						},
					],
				},
			],
		},
	},
	/**
	 * SCIENCEROUTES
	 */
	{
		path: "/science",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Science",
			icon: <FileTextOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Rapid Fire",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_S_RAPID_FIRE_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Science/RapidFire/Question")),
						},
					],
				},
				{
					name: "Periodic-Table",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_SCIENCE_PERIODIC_TABLE,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Science/PeriodicTable/Question")),
						},
					],
				},
				{
					name: "Loose Link",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_SCIENCE_LOOSE_LINK,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Science/LooseLink/AddGame")),
						},
					],
				},
			],
		},
	},
	/**
	 * ENGLISH ROUTE
	 */
	{
		path: "/english",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "English",
			icon: <FileTextOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Rapid Fire",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							icon: <QuestionCircleOutlined />,
							path: URL_ENGLISH_QUESTIONS,
							component: LazyLoader(() => import("Components/English/RapidFire/Question")),
						},
					],
				},
				{
					name: "Cross-word",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_ENGLISH_CROSSWORD,
							icon: <QuestionCircleOutlined />,
							// component: LazyLoader(() => import('Components/English/Crossword')),
							component: LazyLoader(() => import("Components/English/Crossword/CrossWordMain")),
						},
					],
				},
			],
		},
	},
	/*
	 * ECONOMIC ROUTE
	 */
	{
		path: "/economics",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Economics",
			icon: <FileTextOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Rapid Fire",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							icon: <QuestionCircleOutlined />,
							path: URL_ECONOMICS.ECONOMICS_QUESTIONS,
							component: LazyLoader(() => import("Components/Economics/RapidFire/Question")),
						},
					],
				},
				{
					name: "Pursuit",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Round-1",
							path: URL_ECONOMICS.PURSUIT_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Economics/Pursuit/Question")),
						},
						{
							name: "Round-2",
							path: URL_ECONOMICS.CHASER_QUESTIONS,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Economics/Pursuit/ChaserQuestion")),
						},
						// {
						// 	name: 'Round-3',
						// 	path: URL_ECONOMICS.FINAL_QUESTIONS,
						// 	icon: <QuestionCircleOutlined />,
						// 	component: LazyLoader(() => import('Components/Economics/Pursuit/FinalRoundQuestion')),
						// },
					],
				},
			],
		},
	},
	/**
	 * MATHS ROUTE
	 */
	{
		path: "/maths",
		exact: true,
		subnest: true,
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Math",
			icon: <FileTextOutlined />,
			showDivider: true,
			access: ADMIN_ACCESS,
			nested: [
				{
					name: "Connect 4",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_MATHS.CONNECT4_ADD_GAME,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Maths/Connect4/AddGame")),
						},
					],
				},
				{
					name: "Mathahtzee",
					icon: <ClockCircleOutlined />,
					access: ADMIN_ACCESS,
					showDivider: true,
					sub_nested: [
						{
							name: "Questions",
							path: URL_MATHS.MATHAHTZEE_ADD_GAME,
							icon: <QuestionCircleOutlined />,
							component: LazyLoader(() => import("Components/Maths/Mathahtzee/AddGame")),
						},
					],
				},
			],
		},
	},
	{
		path: QUESTION_BANK_MENU,
		exact: true,
		component: LazyLoader(() => import("Components/QuestionBank/QuestionBankMenu")),
		access: All_ACCESS,
		sidebar: {
			name: "Question Bank",
			icon: <FileProtectOutlined />,
		},
	},
	{
		path: QUESTION_BANK,
		exact: true,
		component: LazyLoader(() => import("Components/QuestionBank/QuestionBank")),
		access: SCHOOL_TEACHER_ACCESS,
		// sidebar: {
		//   name: "Question Bank",
		//   icon: <FileProtectOutlined />,
		// }
	},
	{
		path: QUESTION_BANK_SCHOOLS,
		exact: true,
		component: LazyLoader(() => import("Components/QuestionBank/SchoolList")),
		access: ADMIN_ACCESS,
		// sidebar: {
		//   name: "Question Bank",
		//   access: ADMIN_ACCESS,
		//   icon: <FileProtectOutlined />,
		// },
	},
	{
		path: URL_PAGES,
		exact: true,
		component: LazyLoader(() => import("Components/Settings/Pages/StaticContent/StaticContent")),
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Game Static Pages",
			access: ADMIN_ACCESS,
			icon: <FileProtectOutlined />,
		},
	},

	{
		path: URL_LEGAL,
		exact: true,
		component: LazyLoader(() => import("Components/Legal/")),
		access: ADMIN_ACCESS,
		sidebar: {
			name: "Site Information",
			access: ADMIN_ACCESS,
			icon: <ContainerOutlined />,
		},
	},

	{
		path: URL_SUBSCRIPTION + ":schoolId",
		exact: true,
		component: LazyLoader(() => import("Components/Subscription")),
		access: SCHOOL_ACCESS,
		sidebar: {
			name: "Subscription",
			access: SCHOOL_ACCESS,
			icon: <ContainerOutlined />,
		},
	},

	{
		path: URL_SETTINGS,
		simple: true,
		access: All_ACCESS,
		sidebar: {
			name: "Settings",
			icon: <AppstoreOutlined />,
			access: All_ACCESS,
			nested: [
				{
					name: "Edit Profile",
					icon: <UserOutlined />,
					path: URL_EDIT_PROFILE,
					component: LazyLoader(() => import("Components/Settings/Profile/Profile")),
				},
				{
					name: "Change Password",
					icon: <LockOutlined />,
					path: URL_CHANGE_PASSWORD,
					component: LazyLoader(() => import("Components/Settings/ChangePassword/ChangePassword")),
				},
				// {
				//     name    : "App",
				//     path    : URL_SETTINGS_APP,
				//     component: LazyLoader(() => import('Components/Users/Users')),
				// },
			],
		},
	},
];
